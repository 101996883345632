

















































































































































































































































@import "../../../../assets/css/exper/experiDetail";

.record-table {
  margin-bottom: 16px;
}
